import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import {
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Text,
  Input,
  VStack,
} from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import CompanySelect from "../../common/CompanySelect";
import UserPassword from "./UserPassword";
import EditPortalRoles from "./EditPortalRoles";
import EditAdminAppRoles from "./EditAdminAppRoles";

const EditUser = () => {
  const params = useParams();
  const userId = params.user;
  const [user, setUser] = useState(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [password, setPassword] = useState("");
  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const uri = `${getBaseUrl()}/users/${userId}`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setUser(response);
        setIsError(false);
      } else {
        setIsError(true);
        setResultMessage("Error fetching user");
      }
      setIsFetching(false);
    })();
  }, [userId]);
  const submit = async () => {
    const uri = `${getBaseUrl()}/users/${userId}`;
    let body = {
      companyName: user.company_Name,
      receiveNotifications: user.receiveNotifications,
      email: user.email,
      userName: user.userName,
      password: password,
      mfaEnabled: user.mfaEnabled
    };
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.PATCH),
      body: JSON.stringify(body),
    });
    if (request.ok) {
      setIsError(false);
      setResultMessage(`Succesfully updated User: ${user.userName}`);
    } else {
      setIsError(true);
      let responseMessage = await request.json();
      setResultMessage(
        `Error updating User ${user.userName}. ${responseMessage?.detail}`
      );
    }
  };
  const allowSubmit = () => {
    return isPasswordValid;
  };
  const updateUser = (key, value) => {
    setUser((user) => {
      user = { ...user };
      user[key] = value;
      return user;
    });
  };
  return (
    <div>
      <GenericFormContainer
        formMaxWidth={"800px"}
        title={"Edit User"}
        subTitle="Edit an existing Portal User"
        isResultError={isError}
        isSubmitDisabled={!allowSubmit()}
        onSubmitClick={() => submit()}
        isFetching={isFetching}
        resultMessage={resultMessage}
      >
        {user && (
          <VStack align={"flex-start"} p="2">
            <FormControl>
              <FormLabel>User</FormLabel>
              <Text>{user.userName}</Text>
            </FormControl>
            <CompanySelect
              companyName={user.company_Name}
              onCompanyNameChange={(e) => updateUser("company_Name", e)}
            />
            <FormControl>
              <FormLabel htmlFor="userEmailAddressInput">
                Email Address
              </FormLabel>
              <Input
                id="userEmailAddressInput"
                type="email"
                variant="filled"
                maxLength={50}
                value={user.email}
                onChange={(e) => updateUser("email", e.target.value)}
                placeholder="Enter Email Address"
              />
            </FormControl>
            <HStack>
              <FormLabel>Receive Notifications</FormLabel>
              <Switch
                isChecked={user.receiveNotifications}
                onChange={(e) =>
                  updateUser("receiveNotifications", e.target.checked)
                }
              />
            </HStack>
            <HStack>
              <FormLabel>MFA Enabled</FormLabel>
              <Switch
                isChecked={user.mfaEnabled}
                onChange={(e) =>
                  updateUser("mfaEnabled", e.target.checked)
                }
              />
            </HStack>
            <UserPassword
              defaultPassword={""}
              onPasswordChange={(e) => setPassword(e)}
              onValidityChange={(e) => setIsPasswordValid(e)}
            />
          </VStack>
        )}
      </GenericFormContainer>
      <EditAdminAppRoles userId={userId} />
      <EditPortalRoles userId={userId} />
    </div>
  );
};

export default EditUser;
