import React, { useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import TableCore from "../../common/TableCore";
import { useNavigate } from "react-router-dom";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../enums";

const columns = [
  { label: "Name", renderCell: (item) => item.userName },
  {
    label: "Company",
    renderCell: (item) => item.company_Name,
  },
  {
    label: "Notifications",
    renderCell: (item) => item.receiveNotifications.toString(),
  },
  {
    label: "MFA",
    renderCell: (item) => item.mfaEnabled.toString(),
  },
  {
    label: "Disabled",
    renderCell: (item) => item.isDisabled.toString(),
  },
];

const UsersList = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [showDisabled, setShowDisabled] = useState(false);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    async function getUsers() {
      const uri = `${getBaseUrl()}/users?isDisabled=${showDisabled}`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setUsers(response);
      } else {
        console.log("Error fetching users");
      }
    }
    getUsers();
  }, [showDisabled]);
  const data = () => {
    let output = { nodes: [] };
    if (!users || users.length === 0) {
      return output;
    }
    if (!search) {
      output.nodes = users;
    }
    let data = users.filter((x) => {
      return x.userName.toLowerCase().includes(search.toLowerCase());
    });
    output.nodes = data;
    return output;
  };
  const handleRowClick = (action, state) => {
    navigate(`/users/${action.payload.id}`);
  };
  return (
    <Box>
      <FormControl display="flex" alignItems="center" mt="4">
        <FormLabel htmlFor="show-disabled" mb="0">
          Show Disabled?
        </FormLabel>
        <Switch
          id="show-disabled"
          isChecked={showDisabled}
          onChange={(e) => setShowDisabled(e.target.checked)}
        />
      </FormControl>
      <TableCore
        columns={columns}
        data={data()}
        height={"800px"}
        width={"800px"}
        onRowSelect={handleRowClick}
        onSearchChange={(e) => setSearch(e)}
        pageSize={14}
        mode={TABLE_MODE.CLICKABLE}
        showSearch={true}
      />
    </Box>
  );
};

export default UsersList;
