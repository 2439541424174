import React from "react";
import { Stack, Box } from "@chakra-ui/react";
import MenuItem from "./MenuItem";
import { allowByRole } from "../../utils";
import { ROLES } from "../../enums";

const MenuItems = ({ isOpen, roles, isLoggedIn }) => {
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={8}
        align={"center"}
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem to="/">Home</MenuItem>
        <MenuItem to="/company" isVisible={isLoggedIn()}>Company</MenuItem>
        <MenuItem to="/events" isVisible={isLoggedIn()}>Events</MenuItem>
        <MenuItem to="/marvin" isVisible={isLoggedIn()}>Marvin</MenuItem>
        <MenuItem to="/users" isVisible={isLoggedIn()}>Users</MenuItem>
        <MenuItem to="/portal" isVisible={isLoggedIn()}>Notifications</MenuItem>
        <MenuItem to="/orders" isVisible={isLoggedIn()}>Fullfillment</MenuItem>
        <MenuItem to="/accounting" isVisible={allowByRole(roles, ROLES.Accounting) && isLoggedIn()}>Accounting</MenuItem>
        <MenuItem to="/wex" isVisible={allowByRole(roles, ROLES.WEX) && isLoggedIn()}>WEX CC</MenuItem>
        <MenuItem to="/alfred" isVisible={isLoggedIn()}>Alfred</MenuItem>
      </Stack>
    </Box>
  );
};

export default MenuItems;
