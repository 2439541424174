import React from "react";
import { HStack, Link, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useMatch, useResolvedPath } from "react-router-dom";

const SidebarMenuItem = ({ path, label, icon }) => {
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: true });
  const showUnderline = () => (match ? "#f9564f" : "black");

  const isExternal = path.startsWith("http");

  return isExternal ? (
      <Link href={path} target="_blank" mb="10px">
        <HStack color={showUnderline()}>
          {icon}
          <Text>{label}</Text>
        </HStack>
      </Link>
  ) : (
      <Link as={ReactRouterLink} to={path} mb="10px">
        <HStack color={showUnderline()}>
          {icon}
          <Text>{label}</Text>
        </HStack>
      </Link>
  );
};

export default SidebarMenuItem;
