import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";

const ChangeManagement = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");
  const [assignee, setAssignee] = useState("");
  const [datePerformed, setDatePerformed] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [version, setVersion] = useState("");
  const [pullRequest, setPullRequest] = useState("");
  const [releasePipeline, setReleasePipeline] = useState("");
  const [rollbackPipeline, setRollbackPipeline] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const allowSubmit = () => {
    if (type === "Release") {
      return (
        ticketNumber &&
        assignee &&
        datePerformed &&
        title &&
        description &&
        type &&
        version &&
        pullRequest &&
        releasePipeline &&
        rollbackPipeline
      );
    } else {
      return (
        ticketNumber &&
        assignee &&
        datePerformed &&
        title &&
        description &&
        type
      );
    }
  };

  const submit = async () => {
    const uri = `${getBaseUrl()}/changemanagement`;
    setIsFetching(true);
    let body = {
      ticketNumber,
      assignee,
      datePerformed,
      title,
      description,
      type,
      version,
      pullRequest,
      releasePipeline,
      rollbackPipeline,
    };
    try {
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage("Change management details submitted successfully.");
        // Reset form fields
        setTicketNumber("");
        setAssignee("");
        setDatePerformed("");
        setTitle("");
        setDescription("");
        setType("");
        setVersion("");
        setPullRequest("");
        setReleasePipeline("");
        setRollbackPipeline("");
      } else {
        setIsError(true);
        let responseText = await result.text();
        setResultMessage(`Error: ${responseText}`);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
      setResultMessage("An unexpected error occurred.");
    }
    setIsFetching(false);
  };

  return (
    <GenericFormContainer
      formMaxWidth={"600px"}
      title={"Change Management"}
      subTitle="Record and manage changes"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={submit}
      isFetching={isFetching}
      resultMessage={resultMessage}
      width={"800px"}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="ticketNumber">* Ticket Number</FormLabel>
          <Input
            id="ticketNumber"
            type="text"
            value={ticketNumber}
            onChange={(e) => setTicketNumber(e.target.value)}
            placeholder="Enter Ticket Number"
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="assignee">* Assignee</FormLabel>
          <Input
            id="assignee"
            type="text"
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
            placeholder="Enter Assignee"
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="datePerformed">* Date Performed</FormLabel>
          <Input
            id="datePerformed"
            type="datetime-local"
            value={datePerformed}
            onChange={(e) => setDatePerformed(e.target.value)}
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="title">* Title</FormLabel>
          <Input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter Title"
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="description">* Description</FormLabel>
          <Textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="type">* Type</FormLabel>
          <Select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            placeholder="Select Type"
            variant={"filled"}
          >
            <option value="Release">Release</option>
            <option value="Infrastructure Change">Infrastructure Change</option>
            <option value="Database">Database</option>
            <option value="Password Change">Password Change</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="version">Version</FormLabel>
          <Input
            id="version"
            type="text"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            placeholder="Enter Version"
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="pullRequest">Pull Request</FormLabel>
          <Input
            id="pullRequest"
            type="text"
            value={pullRequest}
            onChange={(e) => setPullRequest(e.target.value)}
            placeholder="Enter Pull Request"
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="releasePipeline">Release Pipeline</FormLabel>
          <Input
            id="releasePipeline"
            type="text"
            value={releasePipeline}
            onChange={(e) => setReleasePipeline(e.target.value)}
            placeholder="Enter Release Pipeline"
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="rollbackPipeline">Rollback Pipeline</FormLabel>
          <Input
            id="rollbackPipeline"
            type="text"
            value={rollbackPipeline}
            onChange={(e) => setRollbackPipeline(e.target.value)}
            placeholder="Enter Rollback Pipeline"
            variant={"filled"}
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default ChangeManagement;
